.ContainerLogin{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-image: url('../../Assets/img/fondo.avif'); */
    background-image: url('../../Assets/img/fondo2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.ContainerLeftLogin{
    background-color: blue;
}

.ContainerLogin__Text{
    text-align: center;
    font-size: 30px;
    padding-bottom: 15px;
    font-weight: 600;
}

.FormLogin__Item .ant-form-item-label{
    padding: 0 0 2px !important;
}

.FormLogin__Item{
    margin-bottom: 20px;
}
.FormLogin__Item .ant-form-item-required{
    font-size: 18px !important;
}
.Button-Login{
    width: 299px;
    height: 47px;
    background: #3946ab;
    display: flex;
    align-items: center;
    place-content: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    border-radius: 20px;
    border: none;
    outline: none;
}