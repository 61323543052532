.Button-New{
    margin-bottom: 15px;
}
.Status-Color-Green{
    width: 10px;
    height: 10px;
    background-color: #1eec41;
    border-radius: 100%;
}
.Status-Color-Red{
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
}