.CardFileUpload{
    border: 1px solid rgba(0, 0, 255, 0.29);
    width: 230px;
    padding: 20px;
    border-radius: 15px;
    height: 280px;
    max-height: 280px;
    -webkit-box-shadow: 3px 2px 12px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 2px 12px -4px rgba(0,0,0,0.75);
    box-shadow: 3px 2px 12px -4px rgba(0,0,0,0.75);
}

.CardFileUpload__Info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.CardFileUpload__Actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    row-gap: 5px;
}
.CardFileUpload__Info img{
    width: 100px;
    border-radius: 100%;
}
.Download__Template{
    border: 1px solid #29aa29;
    color: #29aa29;
    font-size: 12px;
    height: 21px;
}
.Download__Template:hover, .Download__Template:active{
    background-color: #29aa29 !important;
    color: #fff !important;
    border: 1px solid #29aa29 !important;
}