.templateTitle { 
    font-size: 17px;
    font-weight: bold;
 }

 .recipientTitle {
    margin: 0;
    font-size: 13px;
 }

 .emailSelector {
    height: auto;
    width: 350px;
    margin-bottom: 5px;
 }

.selectorsContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.emailSelectorContainer {
    display: flex;
    flex-direction: column;
}