.ModalTransitionProduct__Actions{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.ModalTransitionProduct__Title{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}