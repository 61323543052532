.Container-Type-Permissions{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}
.Container-Permissions{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}
.Container-Button-Type-Users .ant-form-item-control-input-content{
    display: flex;
    justify-content: center;
    column-gap: 10px;
}