.container-header{
    height: 40px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.88);
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
}
.header-menu{
    width: 90%;
    line-height: 40px !important;
}
.header-menu-admin{
    line-height: 40px !important;
}
.Menu-Log-Out{
    line-height: 40px !important;
    cursor: pointer;
}