.ContainerBaseline{
    padding: 40px 10px;
}

.Baseline__ButtonAction{
    color: #FFFFFF;
}

.Baseline__Table .ant-table-thead .ant-table-cell{
    font-size: 12px !important;
    padding: 2px 4px !important;
    white-space: nowrap;
    background-color: #bae0ff;
    color: #000000;
}
.Baseline__Table .ant-table-tbody .ant-table-cell{
    padding: 6px 8px !important;
    font-size: 12px !important;
    white-space: nowrap;
}

.ColumnNetLc__Header{
    background-color: yellow;
}
.Baseline__Table th.Col-Yellow{
    background-color: #FFFF00 !important;
}
.Baseline__Table th.Col-Purple{
    background-color: #7030A0 !important;
    color: #fff !important;
}