.Table-Audits .ant-pagination{
    margin: 0 !important;
}
.Table-Audits .ant-table{
    margin-top: 16px !important;
}
.Table-Audits .ant-pagination-total-text{
    order: 10;
    margin-right: 0;
    margin-left: 8px;
}
.Table-Audits .ant-pagination-options{
    display: none;
}
.Table-Audits .ant-table-thead tr th{
    font-size: 12px;
    padding: 3px 8px !important;
    background-color: #004fb8!important;
    color: #FFFFFF !important;
}
.Table-Audits .ant-table-thead tr th::before{
    display: none;
}
.Table-Audits .ant-table-body{
    overflow: hidden !important;
}
.Table-Audits .ant-table-tbody tr td{
    font-size: 11px;
    padding: 3px 8px !important;
}
.Table-Audits .ant-table-tbody tr td div{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Table-Audits.Clients-Products-Pending .ant-pagination{
    margin-top: 10px !important;
}

.Table-Audits.Table-Emails .ant-table-body{
    overflow: auto !important;
}